<template>
  <v-row>
    <div class="text-h5 font-weight-bold mx-4">
      {{ title }}
    </div>
    <!-- <Tooltip v-if="toolTip" :tooltipText="toolTipText"/> -->
  </v-row>
</template>

<script>
// import Tooltip from '../Tooltip/tooltip.vue'
export default {
  name: "SingleTitle",
  // props:["title" ,"toolTip" ,"toolTipText"],
  props: ["title"],
  components: {
    //Tooltip
  }
};
</script>

<style></style>
