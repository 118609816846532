<template>
  <v-container fluid>
    <SecondaryNavigation />
    <SingleTitle title="Admin Console" />
    <v-row>
      <v-col>
        <ProfileCard />
      </v-col>
      <v-col> </v-col>
      <v-col> </v-col>
    </v-row>

    <v-card class="ma-3" flat>
      <v-card-text>
        <v-form v-model="valid" ref="adminConsoleForm">
          <v-row justify="space-between">
            <div class="text-h6 font-weight-bold mx-3 text--primary">
              Stage 1A
            </div>
            <v-card-actions>
              <v-btn
                v-if="editMode.adminConsole"
                @click="saveAdminActivity"
                class="mx-3"
                color="primary"
                slot="activator"
                style="background-color:blue"
                :loading="loading"
                >Save</v-btn
              >
              <v-btn
                @click="enableEditMode"
                v-else
                class="mx-3"
                color="primary"
                style="background-color:blue"
                >Edit</v-btn
              >
            </v-card-actions>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
                Change ot owner *
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Ot owner *
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <!-- <div
                class="text-h6 font-weight-bold my-n3 mx-1 text--primary"
                v-if="!editMode.adminConsole"
              >
                {{ profile.otOwner }}
              </div> -->
              <DataCard :data="profile.otOwner" v-if="!editMode.adminConsole"/>
              <v-select
                v-else
                :disabled="!editMode.adminConsole"
                :items="otOwners"
                placeholder="Select ot owner "
                v-model="profile.otOwner"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          <v-row justify="space-between">
            <div
              class="text-h6 font-weight-bold mx-3 text--primary"
              v-if="editMode.adminConsole"
            >
              Professional platform trainer is procured from *
            </div>
            <div class="text-h6 font-weight-bold mx-3 primary--text" v-else>
              Professional platform trainer is procured from *
            </div>
            
          </v-row>

          <v-row>
            <v-col cols="4">
              <DataCard :data="profile.trainerProcueredFrom" v-if="!editMode.adminConsole"/>
              <v-select
                v-else
                :items="trainerProcuredFrom"
                placeholder="Select from below"
                v-model="profile.trainerProcueredFrom"
                outlined
                :disabled="!editMode.adminConsole"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <div
              class="text-h6 font-weight-bold mx-3 text--primary"
              v-if="editMode.adminConsole"
            >
             Suggested by | Procurement Trigger
            </div>
            <div class="text-h6 font-weight-bold mx-3 primary--text" v-else>
             Suggested by | Procurement Trigger
            </div>
          </v-row>
          <v-row>
            <v-col cols="4">
              <DataCard :data="profile.suggestedBy" v-if="!editMode.adminConsole"/>
              <v-select
                v-else
                :items="suggestedBy"
                placeholder="Select from below"
                v-model="profile.suggestedBy"
                outlined
                :disabled="!editMode.adminConsole"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <div
              class="text-h6 font-weight-bold mx-3 text--primary"
              v-if="editMode.adminConsole"
            >
             Link to asana
            </div>
            <div class="text-h6 font-weight-bold mx-3 primary--text" v-else>
              Link to asana
            </div>
          </v-row>
          <v-row>
            <v-col cols="4">
             <a
                class="text-h6 font-weight-bold my-n1 text--primary"
                v-if="!editMode.adminConsole"
                :href="profile.linkToAsana"
              >
                {{ profile.linkToAsana }}
              </a>
                <v-text-field
                v-else
                :disabled="!editMode.adminConsole"
                placeholder="Link to asana"
                v-model="profile.linkToAsana"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <div
              class="text-h6 font-weight-bold mx-3 text--primary"
              v-if="editMode.adminConsole"
            >
             Raw courses of trainer
            </div>
            <div class="text-h6 font-weight-bold mx-3 primary--text" v-else>
              Raw corses of trainer
            </div>
          </v-row>
          <v-row>
            <v-col cols="4">
             <span
                class="text-h6 font-weight-bold my-n1 text--primary"
                v-if="!editMode.adminConsole"
              >
                {{ profile.rawCoursesTrainer }}
              </span>
                <v-text-field
                v-else
                :disabled="!editMode.adminConsole"
                placeholder="Raw courses of trainer"
                v-model="profile.rawCoursesTrainer"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="space-between">
            <div
              class="text-h6 font-weight-bold mx-3 text--primary"
              v-if="editMode.adminConsole"
            >
             Number of oq's trainer engaged for
            </div>
            <div class="text-h6 font-weight-bold mx-3 primary--text" v-else>
              Number of oq's trainer engaged for
            </div>
          </v-row>
          <v-row>
            <v-col cols="4">
             <span
                class="text-h6 font-weight-bold my-n1 text--primary"
              >
                {{ profile.numberOfOq  && profile.listOfOqs ?profile.numberOfOq:profile.listOfOqs?profile.listOfOqs.length:0}}
              </span>
                
            </v-col>
          </v-row>
          <v-divider v-if="!editMode.adminConsole"></v-divider>
          <div class="text-h6 font-weight-bold  text--primary">
              Stage 1B 
            </div>
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          
          <v-row>
            <v-col cols="12">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
                Training history with OT at the time of onboarding *
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Training history with OT at the time of onboarding *
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-n3">
            <v-col>
              <!-- <div
                class="text-h6 font-weight-bold my-n3 mx-1"
                v-if="!editMode.adminConsole"
              >
                {{ profile.trainerOtHistory ? "Yes" : "No" }}
              </div> -->
              <DataCard :data="getOtHistory(profile.trainerOtHistory)" v-if="!editMode.adminConsole"/>
              <v-radio-group
                row
                v-model="profile.trainerOtHistory"
                :disabled="!editMode.adminConsole"
                v-else
              >
                <v-radio label="Yes" value="true"></v-radio>
                <v-radio label="No" value="false"></v-radio>
                <v-radio label="No Past Data" value="null"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-col cols="12">
          <a class="text-h6 font-weight-bold  blue--text mx-n3"  @click="routeTo" v-if="!editMode.adminConsole">
            Click here to fill 1B Skills and service data :Rough Skills
          </a>
        </v-col>
        <div class="text-h6 font-weight-bold  text--primary">
              Stage 1C
            </div>
         <v-row>
            <v-col cols="8">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
                Any organization trainer is associated with
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Any organization trainer is associated with
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div
                class="text-h6 font-weight-bold  my-n4"
                v-if="!editMode.adminConsole"
              >
                {{ profile.trainerOraginzation }}
              </div>
              <v-text-field
                v-else
                :disabled="!editMode.adminConsole"
                placeholder="Type here...."
                v-model="profile.trainerOraginzation"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
                Validation data for skill & TA
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Validation data for skill & TA
              </div>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="4">
              <div
                class="text-h6 font-weight-bold  my-n4"
                v-if="!editMode.adminConsole"
              >
                {{ profile.validationDataForSkills }}
              </div>
              <v-text-field
                v-else
                :disabled="!editMode.adminConsole"
                placeholder="Type here...."
                v-model="profile.validationDataForSkills"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              Rough trainer band
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
              Rough trainer band
            </div>
          </v-row>
          <v-row>
            <v-col cols="4">
              <div
                class="text-h6 font-weight-bold my-n1 text--primary"
                v-if="!editMode.adminConsole"
              >
                {{ profile.trainerBand }}
              </div>
              <v-select
                v-else
                :disabled="!editMode.adminConsole"
                :items="trainerBand"
                placeholder="Select from below"
                v-model="profile.trainerBand"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              List of OQ's engaged for
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
              List of OQ's engaged for
            </div>
          </v-row>
          <v-row>
            <v-col>
              <div
                class="text-h7 font-weight-bold mx-3"
                v-if="!editMode.adminConsole"
              >
                <v-list v-for="(oqs, index) in profile.listOfOqs" :key="index">
                  {{ oqs }}
                </v-list>
              </div>
              <v-combobox
                v-else
                class="elevation-0"
                label=""
                chips
                outlined
                multiple
                clearable
                :disabled="!editMode.adminConsole"
                v-model="profile.listOfOqs"
                placeholder="After typing press enter to input more values"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    :input-value="select"
                    close
                    @click:close="removeOQ(data.item)"
                    text-color="black"
                    small
                  >
                    <strong>{{ data.item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              List of trainings delivered via ot
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
              List of trainings delivered via ot
            </div>
          </v-row>
          <v-row>
            <v-col>
              <div
                class="text-h7 font-weight-bold mx-3"
                v-if="!editMode.adminConsole"
              >
                <v-list
                  v-for="(trainingsOt,
                  index) in profile.deliveredTrainingsThroughOt"
                  :key="index"
                >
                  {{ trainingsOt }}
                </v-list>
              </div>
              <v-combobox
                v-else
                class="elevation-0"
                label=""
                chips
                outlined
                multiple
                clearable
                :disabled="!editMode.adminConsole"
                v-model="profile.deliveredTrainingsThroughOt"
                placeholder="After typing press enter to input more values"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip
                    :input-value="select"
                    close
                    @click:close="removeTrainingsDeliveredViaOT(data.item)"
                    text-color="black"
                    small
                  >
                    <strong>{{ data.item }}</strong>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          
          <!-- <v-divider v-if="!editMode.adminConsole"></v-divider> -->
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              Dates availability
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
               Dates availability
            </div>
          </v-row>
          <v-row>
            <span class="text-h6 font-weight-bold text--primary mx-3" v-if="!editMode.adminConsole">{{
              profile.dateAvailable
            }}</span>
             <v-select
                v-else
                :disabled="!editMode.adminConsole"
                :items="datesAvailable"
                placeholder="Select from below"
                v-model="profile.dateAvailable"
                outlined
              ></v-select>
          </v-row>
           <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              Additonal constraints
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
                Additonal Constraints
            </div>
          </v-row>
          <v-row>
            <span class="text-h6 font-weight-bold text--primary mx-3" v-if="!editMode.adminConsole">{{
              profile.additionalConstraints 
            }}</span>
             <v-text-field
                v-else
                :disabled="!editMode.adminConsole"
                placeholder="Any addtional constraints"
                v-model="profile.additionalConstraints"
                outlined
              ></v-text-field>
          </v-row>
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              Trainer account created by
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
              Trainer account created by
            </div>
          </v-row>
          <v-row>
            <span class="text-h6 font-weight-bold text--primary mx-3">{{
              profile.createdBy
            }}</span>
          </v-row>
          <v-divider v-if="!editMode.adminConsole"></v-divider>
          
          <v-divider v-if="!editMode.adminConsole"></v-divider>
          <v-row>
            <div
              class="text-h6 font-weight-bold text--primary mx-3"
              v-if="editMode.adminConsole"
            >
              Trainer journey status
            </div>
            <div class="text-h6 font-weight-bold primary--text mx-3" v-else>
              Trainer journey status
            </div>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
               Stage 1B :Collect raw detials
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Stage 1B :Collect raw detials
              </div>
            </v-col>
            <v-col cols="5" class="mx-n9 my-n3">
              <v-radio-group
                v-if="editMode.adminConsole"
                row
                v-model="TrainerJourney.dataVerfication"
                :disabled="!editMode.adminConsole"
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold my-3">
                {{
                  TrainerJourney.dataVerfication == 1
                    ? "Not Started"
                    : TrainerJourney.dataVerfication == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
               Stage 1C :Collect basic detials
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Stage 1C :Collect basic detials
              </div>
            </v-col>
            <v-col cols="5" class="mx-n9 my-n3">
              <v-radio-group
                v-if="editMode.adminConsole"
                row
                v-model="TrainerJourney.basicDetials"
                :disabled="!editMode.adminConsole || TrainerJourney.dataVerfication!=3"
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold my-3">
                {{
                  TrainerJourney.basicDetials == 1
                    ? "Not Started"
                    : TrainerJourney.basicDetials == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary my-n3"
                v-if="editMode.adminConsole"
              >
                Stage 2A:  Due deligence
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                 Stage 2A:  Due deligence
              </div>
              <!-- <div class="text-h6 font-weight-bold my-n3">
                Due deligence
              </div> -->
            </v-col>
            <v-col
              ><v-radio-group
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                row
                v-model="TrainerJourney.dueDeligence"
                :disabled="
                  !editMode.adminConsole || TrainerJourney.basicDetials != 3
                "
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{
                  TrainerJourney.dueDeligence == 1
                    ? "Not Started"
                    : TrainerJourney.dueDeligence == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
               Stage 2B: Price negotiation
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Stage 2B: Price negotiation
              </div>
            </v-col>
            <v-col
              ><v-radio-group
                row
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                v-model="TrainerJourney.priceNegotiation"
                :disabled="
                  !editMode.adminConsole || TrainerJourney.dueDeligence != 3
                "
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{
                  TrainerJourney.priceNegotiation == 1
                    ? "Not Started"
                    : TrainerJourney.priceNegotiation == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
              Stage 3A:  Legal signing
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Stage 3A:  Legal signing
              </div>
            </v-col>
            <v-col
              ><v-radio-group
                row
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                v-model="TrainerJourney.legalSigning"
                :disabled="
                  !editMode.adminConsole || TrainerJourney.priceNegotiation != 3
                "
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{
                  TrainerJourney.legalSigning == 1
                    ? "Not Started"
                    : TrainerJourney.legalSigning == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
              Stage 3B: Process alignment
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
              Stage 3B:  Process alignment
              </div>
            </v-col>
            <v-col
              ><v-radio-group
                row
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                v-model="TrainerJourney.processAlignment"
                :disabled="
                  !editMode.adminConsole || TrainerJourney.legalSigning != 3
                "
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{
                  TrainerJourney.processAlignment == 1
                    ? "Not Started"
                    : TrainerJourney.processAlignment == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
              Stage 4:  Data Richness
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Stage 4:  Data Richness
              </div>
            </v-col>
            <v-col
              ><v-radio-group
                row
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                v-model="TrainerJourney.dataRichnes"
                :disabled="!editMode.adminConsole || TrainerJourney.processAlignment != 3">
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{TrainerJourney.dataRichnes == 1
                    ? "Not Started"
                    : TrainerJourney.dataRichnes == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
           <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
              Meta Tagging  
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
                Meta Tagging 
              </div>
            </v-col>
            <v-col
              ><v-radio-group
                row
                v-if="editMode.adminConsole"
                class="mx-n9 my-n3"
                v-model="TrainerJourney.metaTag"
                :disabled="
                  !editMode.adminConsole || TrainerJourney.dataRichness != 3"
              >
                <v-radio label="Not Started" value="1"></v-radio>
                <v-radio label="In-Progress" value="2"></v-radio>
                <v-radio label="Completed" value="3"></v-radio>
              </v-radio-group>
              <div v-else class="text-h6 font-weight-bold mx-n9">
                {{
                  TrainerJourney.metaTag == 1
                    ? "Not Started"
                    : TrainerJourney.metaTag == 2
                    ? "In-Progress"
                    : "Completed"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <div
                class="text-h6 font-weight-bold text--primary"
                v-if="editMode.adminConsole"
              >
              Trainer Onboarding status 
              </div>
              <div class="text-h6 font-weight-bold primary--text" v-else>
              Trainer Onboarding status 
              </div>
            </v-col>
            <v-col>
             <div class="text-h6 font-weight-bold mx-n9">
               {{TrainerJourney.trainerOnboardingStatus == 1
                    ? "Not Started"
                    : TrainerJourney.trainerOnboardingStatus == 2
                    ? "In-Progress"
                    : "Onboarded"}}
             </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div :class="editMode.adminConsole ? activeClass : nonActive">
      Admin comments
    </div>
    <AdminComments
      :showAddButton="editMode.adminConsole"
      type="ADMIN_CONSOLE"
    />
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import SecondaryNavigation from "../../../components/SecondaryNavigation/SecondaryNavigation.vue";
import ProfileCard from "../../../components/ProfileCard/ProfileCard.vue";
import AdminComments from "../../../components/AdminComments/AdminComments.vue";
import SingleTitle from "../../../components/SingleTitle/singleTitle.vue";
import { mapState } from "vuex";
import DataCard from '../../../components/datacard.vue'

export default {
  name: "AdminConsole",
  components: {
    SecondaryNavigation,
    ProfileCard,
    AdminComments,
    SingleTitle,
    DataCard
  },

  data() {
    return {
      activeClass: "text-h6 font-weight-bold mx-3",
      nonActive: "text-h6 primary--text font-weight-bold",
      journeryRadio: [
        { label: "Not Started", value: 1 },
        { label: "In-Progress", value: 2 },
        { label: "Completed", value: 3 }
      ],
      valid: false,
      select: ["add-tags-with", "enter", "tab", "paste"],
      trainerProcuredFrom: [
        "Linkedin",
        "Google",
        "Udemy",
        "Quora",
        "Trainer reference",
        "Social Media-LinkedIn-Groups",
        "Customer reference",
        "Vendor",
        "Trainer-Platform (inbound)",
        "Social Media-FB-Groups",
        "Social Media-Youtube",
        "PluralSight",
        "Whatsapp-group"
      ],
      trainerBand: [
        "Rough Distinguished",
        "Rough Excellent",
        "Rough Decent",
        "Rough Good",
        "Distinguished",
        "Excellent",
        "Decent",
        "Good"
      ],
      datesAvailable:[
        "Inform 1 week before",
        "Inform 2 week before",
 "Inform 3 week before",
 "Inform 1 month before"
      ],
      createdBy: "David Raj",
      otOwners: ["Sowmya S" ,"Krutika Parmar","Nikita Richhariya","Atreye Gupta","Richa Shukla","Samarth Gupta"],
      suggestedBy:["Sujeet","Parul","Richa","Divya","Sowmya","Sheuli","Sai","Jobin","Shakshi","Khushboo","Atreye Gupta"]
    };
  },
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      loading: "loading",
      editMode: "editMode",
      TrainerJourney: "TrainerJourney"
    })
  },
  created() {
    this.$store.dispatch("trainerProfile/getTrainerJorney", this.profile);
  },
  methods: {
    saveAdminActivity() {
      if (this.$refs.adminConsoleForm.validate()) {
       this.profile.numberOfOq =this.profile.listOfOqs?this.profile.listOfOqs.length:0
        
        // create an object dispatch to store
        let trainerObject = {
          TrainerJourney: this.TrainerJourney,
          profile: this.profile
        };
        this.$store.dispatch(
          "trainerProfile/upateTrainerAndTrainerJourney",
          trainerObject
        );
      }
    },
    removeTrainingsDeliveredViaOT(item) {
      this.profile.deliveredTrainingsThroughOt.splice(
        this.profile.deliveredTrainingsThroughOt.indexOf(item),
        1
      );
    },
    removeOQ(item) {
      console.log("list of oq remove   ----", item);
      this.profile.listOfOqs.splice(this.profile.listOfOqs.indexOf(item), 1);
      // this.profile.listOfOQ = [...this.listOfOQ];
    },
    routeTo(){
      this.$router.push('/pages/skills-service');
    },
    enableEditMode() {
      let editMode = this.$store.dispatch(
        "trainerProfile/changeEditMode",
        "UPDATE_ADMIN_CONSOLE"
      );
      console.log("edmit mode return ", editMode);
    },
    getOtHistory(data){
      if(data){
        return "YES"
      }else{
        return "NO"
      }
    }
  }
};
</script>

<style></style>
