<template>
  <v-card outlined flat width="500px">
    <v-row justify="center">
      <v-col cols="12">
        <div class="text-h7 font-weight-bold mx-2  text--primary">
            {{ data }}
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "datacard",
  props:["data"]
};
</script>
<style></style>
