<template>
  <v-card flat width="550px" max-width="700px">
    <v-container fluid fill-heigh>
      <v-row no-gutters>
        <v-col cols="4">
          <v-avatar size="140px">
            <v-img :src="profilePictureSrc" alt="../../assets/ot black.png" @error="imageNotValid"> <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
      </v-img>
          </v-avatar>
        </v-col>
        <v-col cols="8">
          <v-row>
            <div class="text-h6 font-weight-bold mx-6 my-2">
              {{ profile.firstName }} {{profile.middleName}} {{ profile.lastName }}
            </div>
          </v-row>
          <v-divider v-if="profile.primaryEmailId"></v-divider>
          <v-row>
            <div class="text-h6 font-weight-bold  mx-6 my-2">
            {{ profile.primaryEmailId }}
          </div>
          </v-row>
          <v-divider v-if="profile.trainerBand"></v-divider>
          <v-row v-if="profile.trainerBand">
            <div class="text-h6 font-weight-bold primary--text mx-6 my-2">
            Training band : <Span class="text--primary"> {{profile.trainerBand }}</Span> 
          </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ProfileCard",
  computed: {
    ...mapState("trainerProfile", {
      profile: "profile",
      profilePictureSrc: "profilePictureSrc"
    })
  },
  methods: {
    imageNotValid() {
      this.$store.dispatch("trainerProfile/viewProfilePicture");
    }
  }
};
</script>

<style></style>
